.banner-hero{
    background-image: url(./../../../../public/assets/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
#banner-heading{
    border-radius: 5px 5px 0px 0px;
    background-color: rgba(255, 255, 255,0.7);
    padding: 10px;
    margin: 0px;
}
#banner-para{
    border-radius: 0px 0px 5px 5px;
    padding: 5px;
    background-color:hsla(204, 100%, 24%, 0.6);
    color: white ;
}
#banner-text{
    padding-top: 50px;
    padding-bottom: 50px;
}
.cta-button {
    background-color: #004a7c;
    color: white;
    font-size: 25px;
    padding: 12px 33px;
    text-decoration: none;
    border-radius: 15px;
    transition: 0.4s;
}
.cta-button:hover{
    background-color: white;
    color: #004a7c;
    box-shadow: 0px 3px 5px grey;
}
.intro-section {
    padding: 60px 20px;
    background-color: #fafafa;
    text-align: center;
}
  
.intro-heading {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.intro-subheading {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 20px;
}

.intro-text {
  font-size: 1.1rem;
  max-width: 650px;
  margin: 0 auto 30px;
}

.how-works-section{
    text-align: center;
}

.how-works-h{
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 10px;
}
.how-works-h-rider{
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 10px;
}
.rider-image{
    width: 100%;
    height: auto;
    border-radius: 15px;
}
.rider-works-steps-ul{
    list-style: none;
    text-align: left;
    margin-top: 20px;
}

.how-works-h-driver{
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 10px;
}
.driver-image{
    width: 100%;
    height: auto;
    border-radius: 15px;
}
.driver-works-steps-ul{
    list-style: none;
    text-align: left;
    margin-top: 30px;
}
.how-works-bold{
    color: #004a7c;
}
.how-wirks-icons{
    margin-right: 5px;
    vertical-align: middle;
}

/* SafetyAndSecurity  styling */

.safety-security-container{
    text-align: center;
    background-color: #fafafa;
}
.safety-security-heading{
    font-size: 2.5rem;
    margin-top: 30px;
    font-weight: 600;
    margin-bottom: 5px;
    padding: 30px 0px;
}
.rider-and-driver-image{
    width: 100%;
    height: auto;
    border-radius: 15px;
}
.safetySecurity-bold{
    color: #004a7c;
}
.safetySecurity-icons{
    margin-right: 5px;
    vertical-align: middle;
}

/* Join refferal programe styling */

.referral-section {
    padding: 40px 20px;
    text-align: center;
}
  
.referral-section-heading {
    font-size: 2.5rem;
    font-weight: 600;
}

.referral-section-subheading {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.referral-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
}


/* Wht Rydeon styling here */

.why-choose-section {
  padding: 60px 20px;
  background-color: #fafafa;
  text-align: center;
}
/* .why-choose-part{
    
} */
.why-choose-icons{
    color: #004a7c;
    font-size: 40px !important;
    padding: 4px;
    border-radius: 50%;
    border: 1px solid grey;
}

.why-choose-heading{
    font-size: 2.5rem;
    font-weight: 600;
}
.why-choose-subheading{
    font-size: 1.5rem; 
}

