/* SmallHeader Styling */
.rydeon-header {
    background-color: #fafafa;
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
}

.small-nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-brand-image {
  height: 40px; /* Adjust logo size */
  margin-right: 10px;
}

.brand-nav-text {
  font-size: 1.5rem;
  font-weight: bold;
  color: #004a7c;
}
.nav-link {
  font-size: 1rem;
  margin-right: 20px;
  text-decoration: none;
  font-weight: 500;
}

.nav-link:hover {
  color: #004a7c;
}

/* Hamburger Menu (Mobile) */
.navbar-toggler {
  background: none;
  border: none;
}

.navbar-toggler-icon {
  width: 30px;
  height: 2px;
  background-color: #333;
  display: block;
  margin: 6px 0;
}

@media (max-width: 522px) {
    .nav-other{
        display: none;
    }
}

/* Footer styling */

footer{
    background-color: #004a7c;
    padding: 10px;
}
.contact-anchor{
    text-decoration: none;
    display: block;
    padding: 0px;
    font-size: 18px;
    color: white;
    cursor: pointer;
    transition: 0.3s;
}
.contact-anchor:hover{
    text-shadow: 0px 3px 20px black;
}
.rydeon-footer-ul{
    list-style: none;
    display: flex;
    padding: 0px;
}
.footer-links-heading{
    font-size: 30px;
    font-weight: 600;
    color: white;
}

.social-links-icons{
    background-color: white;
    color: #004a7c;
    padding: 5px;
    border-radius: 5px;
    margin: 5px;
    border: 1px solid white;
    transition: 0.3s !important;
}
.social-links-icons:hover{
    background-color: #004a7c;
    color: white;
}

/* .app-downlaod-row{
    
} */
.app-download-image{
    width: 128px;
    height: auto;
    margin: 0px 10px 10px 0px;
    cursor: pointer;
}


.copyright-footer{
    background-color: #fafafa;
    color: #004a7c;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 600; 
    height: 30px;
    margin: auto;
}

.text-404{
  font-size: 8em;
  color: #bcbcbc;
}